.deletionContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 27px;
}

.userNotFound{
    margin-bottom: 0px;
}
.deletionHeader{
    color: #1D2128;
    font-family: "Mulish", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
 
}

.deletAccountDetails{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.deletAccountDetails > p{
    color: #1D2128;
    font-family: "Mulish", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.deletAccountDetails > p.email{
    word-break: break-all;
}

.deletAccountButtons{
    display: flex;
    justify-content: space-between;
}

.cancel{
    padding-top: 11px;
    padding-bottom: 11px;
    width: 134px;
    border-radius: 8px;
    background: linear-gradient(90deg, #FFDB6C -9.01%, #F7B314 127.45%);
}

.confirm{
    padding-top: 11px;
    padding-bottom: 11px;
    width: 134px;
    border-radius: 8px;
background:  #F6F6F6;
}

.notFoundEdit{
    padding-top: 11px;
    padding-bottom: 11px;
    width: 134px;
    border-radius: 8px;
    background: linear-gradient(90deg, #FFDB6C -9.01%, #F7B314 127.45%);
}

.RequestOK{
    width: 100%;
}
.notFoundCancel{
       padding-top: 11px;
    padding-bottom: 11px;
    width: 134px;
    border-radius: 8px;
background:  #F6F6F6;
}

.remainOtpAlert{
    color: #424955;
    font-family: "Mulish", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
}
.loader {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid black;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }