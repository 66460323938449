@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Epilogue:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: Gilroy;
  src: url("../src/font/Gilroy-Regular.ttf");
}

@font-face {
  font-family: Gilroybold;
  src: url("../src/font/Gilroy-Bold.ttf");
}

:root {
  --font: Gilroy;
  --font-bold: Gilroybold;
  --header-color: #221e1f;
  --Text-grey: #969696;
}
.logoImg {
  width: 160px;
  height: 81px;
  align-self: center;
}
.accountForm {
  max-width: 385px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
}
.accountFormHeadline {
  color: #515151;
  font-family: "Mulish", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}
.partnerInformation {
  max-width: 378px;
  /* margin: 0 auto; */
  padding: 38px 39px 32px 39px;
  border-radius: 6px;
  border: 1px solid #dee1e6;
  background: #fff;
}

.headerInfo {
  display: flex;
  gap: 10px;
}
.headerInfo > h3 {
  color: #1d2128;
  font-family: "Epilogue", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  margin-bottom: 18px;
}
.partnerInformation > form > h3 {
  color: #424955;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 8px;
}
.inputNumber {
  border-radius: 4px;
  background: #f3f4f6;
  display: flex;
  height: 35px;
  text-align: center;
  align-items: center;
  margin-bottom: 9px;
}
.inputNumber > input {
  background: #f3f4f6;
  padding-left: 5px;
}
.inputNumber > input {
  outline: transparent;
  border: transparent;
}
.inputNumber > span{
  padding-left: 5px ;
}
.partnerInformation > form > button , .confirmButton{
  width: 100%;
  border-radius: 8px;
  background: linear-gradient(90deg, #ffdb6c -9.01%, #f7b314 127.45%);
  padding-top: 11px ;
  padding-bottom: 11px;
  color: v #0e0e0e;
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 11px;
}
.codeNotRecieved {
  display: flex;
  gap: 2px;
}
.codeNotRecieved > h4 {
  color: #424955;
  font-family: "Mulish", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}
.resend {
  color: #f9bf2f;
  font-family: "Mulish", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}
.otpTimer{
  color: #424955;
  font-family: "Mulish", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; 
}

.divider {
  background: #ddd;
  max-width: 1200px;
  margin: 40px auto;
  height: 1px;
}
.stepsTopContainer {
  max-width: 633px;
  margin: 0 auto;
}
.stepsHeader {
  color: #515151;
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 50px;
}
.HomeContainer {
  max-width: 525px;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.header {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;
}
.section h1 {
  font-family: var(--font-bold);
  color: var(--header-color);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.intersection {
  height: 2px;
  background: #f7b314;
}

.description {
  color: var(--Text-grey);
  font-family: var(--font);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.imgPart {
  margin-left: 30px;
}
.ScImgGt3 {
  max-width: 230px !important;
  margin-left: 118px;
}

.errorMess{
  display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
}



@media screen and (max-width: 525px) {
  .HomeContainer {
    max-width: 345px;
    margin: 0 auto;
    padding-top: 20px;
    gap: 32px;
  }
  .header h1 {
    font-size: 14px;
  }
  .intersection {
    height: 2px;
  }
  .header {
    gap: 6px;
  }
  .description {
    font-size: 10px;
  }
  .header {
    margin-bottom: 20px;
  }
  .imgPart {
    margin-left: 21px;
  }
  .imgPart img {
    max-width: 228px;
  }
  .ScImgGt3 {
    max-width: 160px !important;
    margin-left: 74px;
  }
  .accountForm {
    max-width: 350px;
  }
  .partnerInformation{
    max-width: 340px;
  }
  
}
@media screen and (max-width: 360px) {
  .HomeContainer {
    max-width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
  .imgPart {
    margin-left: 15px;
  }
  .accountForm {
    max-width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
  .partnerInformation{
    max-width: 100%;
    padding-left: 16px;
    padding-right: 16px; 
  }

}

